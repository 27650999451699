// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// NGX-Bootstrap Modules
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { nlLocale } from 'ngx-bootstrap/locale';

// Custom Components
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

// Custom Directives
import { DropdownDirective } from './dropdown.directive';

// Custom Pipes
import { SortPipe } from './sort.pipe';

defineLocale('nl', nlLocale);

@NgModule({
  declarations: [SortPipe, DropdownDirective, LoadingSpinnerComponent],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    SortPipe,
    FormsModule,
    CommonModule,
    TimepickerModule,
    DropdownDirective,
    BsDatepickerModule,
    ReactiveFormsModule,
    LoadingSpinnerComponent,
  ],
})
export class SharedModule {}
