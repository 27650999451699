export class Authentication {
  constructor(
    public id: string,
    public email: string,
    public username: string,
    public role: string,
    private _token: string,
    private _tokenExpirationDate: Date
  ) {}

  get token(): string {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate)
      return null;

    return this._token;
  }
}
