<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h2 class="text-center">Oeps!</h2>
    <br />
    <blockquote class="text-center">
      <p>Sorry, we kunnen deze pagina niet meer vinden</p>
      <footer>
        Het lijkt erop dat deze pagina niet (meer) bestaat of misschien verhuisd
        is.
      </footer>
    </blockquote>
    <div class="text-center">
      <button type="button" class="btn btn-block btn-primary" routerLink="/">
        Home Pagina
      </button>
    </div>
  </div>
</div>
