<div class="row">
  <div class="col-xs-12">
    <h3>Meldkamerspel Mission Generator</h3>
    <p>
      Release: <span class="badge badge-danger">{{ version }}</span>
    </p>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-xs-12 col-md-6">
    <div class="alert alert-warning">
      <p>
        <span class="glyphicon glyphicon-lock"></span>
        <b>End of Life Notice</b>
        <br>
        In de komende maanden zal deze versie van Mission Generator uitgefaseerd worden.
        <br><br>
        Mogelijk zullen individuele onderdelen van de huidige versie tijdens deze overgang uitgeschakeld worden, en vervangen worden door een linkje naar het nieuwe platform.
        <br><br>
        Mochten bepaalde onderdelen overwachts niet beschikbaarzijn of naar behoren werken, neem dan contact op via Discord.
      </p>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <!-- Content -->
    <h4>Facebook Calendar (Lampe)</h4>
    <div class="alert alert-info">
      <p>
        <span class="glyphicon glyphicon-lock"></span>
        <b>Uitgefaseerd</b>
        <br>
        Gebruik voor het plannen van activaties het nieuwe Mission Generator platform via <a href="https://app.missiongenerator.nl">https://app.missiongenerator.nl</a>
      </p>
    </div>
    <br />
  </div>
</div>
