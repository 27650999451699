// Angular Modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Custom Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';

// Custom Guards
import { AuthenticationGuard } from './authentication/authentication-guard.service';
import { AdministrationGuard } from './authentication/administration-guard.service';

// Custom Interceptors
import { AuthenticationInterceptor } from './authentication/authentication-interceptor.service';

// Custom Modules
import { SharedModule } from './shared/shared.module';

const routes: Routes = [
  // General
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
  },

  // Guarded Modules
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'generator',
    loadChildren: () =>
      import('./generator/generator.module').then((m) => m.GeneratorModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
    canActivate: [AuthenticationGuard, AdministrationGuard],
  },
  // Non-Guarded Modules
  {
    path: 'users',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },

  // Exception Handling
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    NotFoundComponent,
  ],
  imports: [
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
