import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { Authentication } from '../authentication/authentication.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  private subscription: Subscription;

  public collapsed: boolean = true;
  public isAuthenticated: boolean = false;
  public isAdministrator: boolean = false;

  constructor(private authentication: AuthenticationService) {}

  ngOnInit(): void {
    this.subscription = this.authentication.user.subscribe(
      (user: Authentication) => {
        this.isAuthenticated = !!user;
        this.isAdministrator = !!user && user.role === 'Administrator';
      }
    );
  }

  onNavigation(): void {
    this.collapsed = true;
  }

  onSignOut(): void {
    this.authentication.signOut();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
