<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header">
      <!-- Navbar Toggler -->
      <button
        type="button"
        class="navbar-toggle"
        (click)="collapsed = !collapsed"
      >
        <span class="icon-bar" *ngFor="let iconBar of [1, 2, 3]"></span>
      </button>
      <!-- Navbar Brand -->
      <a routerLink="/" (click)="onNavigation()" class="navbar-brand"
        >Mission Generator</a
      >
    </div>
    <div
      class="navbar-collapse"
      [class.collapse]="collapsed"
      (window:resize)="collapsed = true"
    >
      <ul class="nav navbar-nav">
        <!-- Buttons: 'Home' -->
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="isAuthenticated"
        >
          <a routerLink="/" (click)="onNavigation()"
            ><span class="glyphicon glyphicon-home"></span> Home</a
          >
        </li>
        <!-- Dropdown: 'Generator' -->
        <li
          class="dropdown"
          appDropdown
          routerLinkActive="active"
          *ngIf="isAuthenticated"
        >
          <a class="dropdown-toggle" style="cursor: pointer">
            <span class="glyphicon glyphicon-cog"></span> Generator
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <a routerLink="/generator" (click)="onNavigation()"
                ><span class="glyphicon glyphicon-cog"></span> Generator</a
              >
            </li>
            <!-- <li>
              <a routerLink="/calendar" (click)="onNavigation()"
                ><span class="glyphicon glyphicon-calendar"></span>
                Inzetmaandag</a
              >
            </li> -->
          </ul>
        </li>
        <!-- Dropdown: 'Missions' -->
        <!-- <li
          class="dropdown"
          appDropdown
          routerLinkActive="active"
          *ngIf="isAuthenticated"
        >
          <a class="dropdown-toggle" style="cursor: pointer">
            <span class="glyphicon glyphicon-fire"></span> Meldingen
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <a routerLink="/missions/overview" (click)="onNavigation()"
                ><span class="glyphicon glyphicon-fire"></span> Meldingen</a
              >
            </li>
            <li>
              <a routerLink="/missions/changes" (click)="onNavigation()"
                ><span class="glyphicon glyphicon-edit"></span> Wijzigingen</a
              >
            </li>
          </ul>
        </li> -->
        <!-- Dropdown: 'Administration' -->
        <li
          class="dropdown"
          appDropdown
          routerLinkActive="active"
          *ngIf="isAdministrator"
        >
          <a class="dropdown-toggle" style="cursor: pointer">
            <span class="glyphicon glyphicon-hdd"></span> Administratie
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <a routerLink="/administration/settings" (click)="onNavigation()"
                ><span class="glyphicon glyphicon-wrench"></span>
                Instellingen</a
              >
            </li>
            <li>
              <a routerLink="/administration/database" (click)="onNavigation()"
                ><span class="glyphicon glyphicon-hdd"></span> Database</a
              >
            </li>
            <li>
              <a routerLink="/administration/users" (click)="onNavigation()"
                ><span class="glyphicon glyphicon-user"></span> Gebruikers</a
              >
            </li>
          </ul>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Buttons: 'Sign In' -->
        <li routerLinkActive="active" *ngIf="!isAuthenticated">
          <a routerLink="/users/sign-in" (click)="onNavigation()"
            ><span class="glyphicon glyphicon-log-in"></span> Inloggen</a
          >
        </li>
        <!-- Dropdown: 'Account' -->
        <li
          class="dropdown"
          appDropdown
          routerLinkActive="active"
          *ngIf="isAuthenticated"
        >
          <a class="dropdown-toggle" style="cursor: pointer">
            <span class="glyphicon glyphicon-user"></span> Account
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <a routerLink="/account" (click)="onNavigation()"
                ><span class="glyphicon glyphicon-user"></span> Account</a
              >
            </li>
            <li>
              <a style="cursor: pointer" (click)="onSignOut(); onNavigation()"
                ><span class="glyphicon glyphicon-log-out"></span> Uitloggen</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
