import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { take, exhaustMap } from 'rxjs/operators';

import { Authentication } from './authentication.model';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authentication: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authentication.user.pipe(
      take(1),
      exhaustMap((user: Authentication) => {
        if (!user) return next.handle(req);

        const modifiedRequest = req.clone({
          headers: new HttpHeaders().set(
            'Authorization',
            `Bearer ${user.token}`
          ),
        });
        return next.handle(modifiedRequest);
      })
    );
  }
}
