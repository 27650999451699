import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

export interface StorageResponse<A> {
  meta?: {
    token?: string;
    expiresIn?: number;
  };
  links?: {
    self?: string;
  };
  data?: {
    id: string;
    type: string;
    attributes: A;
  };
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(private http: HttpClient) {}

  public HEAD(path: string): Observable<any> {
    return this.http
      .head(environment.API_URL + path)
      .pipe(catchError(this.handleError));
  }

  public GET(path: string): Observable<any> {
    return this.http
      .get(environment.API_URL + path)
      .pipe(catchError(this.handleError));
  }

  public POST(path: string, body: object): Observable<any> {
    return this.http
      .post(environment.API_URL + path, body)
      .pipe(catchError(this.handleError));
  }

  public PATCH(path: string, body: object): Observable<any> {
    return this.http
      .patch(environment.API_URL + path, body)
      .pipe(catchError(this.handleError));
  }

  public DELETE(path: string): Observable<any> {
    return this.http
      .delete(environment.API_URL + path)
      .pipe(catchError(this.handleError));
  }

  public GET_O(path: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.API_URL + path)
        .pipe(
          map((response) => {
            return response['data'].map((item) => {
              return { id: item['id'], ...item['attributes'] };
            });
          })
        )
        .subscribe((response) => {
          resolve(response);
        });
    });
  }

  public createRequest(
    type: string,
    options: { id?: string; attributes?: object; forbidden?: string[] }
  ): object {
    const id = options && options.id ? options.id : undefined;
    const attributes =
      options && options.attributes ? options.attributes : undefined;

    if (options && options.forbidden) {
      options.forbidden.forEach((key) => {
        delete attributes[key];
      });
    }

    return {
      data: {
        id: id,
        type: type,
        attributes: attributes,
      },
    };
  }

  private handleError(error: HttpErrorResponse) {
    let message = 'Er is een onbekende fout opgetreden.';

    if (error.status === 0) message = 'Geen verbinding mogelijk met de server.';

    if (!error.error || !error.error.errors) return throwError(message);

    switch (error.error.errors[0].code) {
      case 'INVALID_CREDENTIALS':
        message = 'Onjuist e-mailadres of wachtwoord.';
        break;

      case 'INSECURE_PASSWORD':
        message = 'Wachtwoord voldoet niet aan de vereisten.';
        break;

      case 'EXPIRED_TOKEN':
        message = 'Wachtwoord reset token is verlopen.';
        break;

      case 'INVALID_TOKEN':
        message = 'Wachtwoord reset token is ongeldig.';
        break;

      case 'TYPE_VIOLATION': {
        const field = error.error.errors[0].detail.split("'")[1];
        message = `De waarde voor "${field}" is ongeldig.`;
        break;
      }

      case 'UNIQUENESS_VIOLATION': {
        const field = error.error.errors[0].detail.split("'")[1];
        message = `De waarde voor "${field}" is reeds in gebruik.`;
        break;
      }
    }

    return throwError(message);
  }
}
