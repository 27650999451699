import {
  Directive,
  HostBinding,
  ElementRef,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[appDropdown]',
})
export class DropdownDirective {
  @HostBinding('class.open')
  public isOpen: boolean = false;

  constructor(private element: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public toggleOpen(event: Event) {
    this.isOpen = this.element.nativeElement.contains(event.target)
      ? !this.isOpen
      : false;
  }
}
