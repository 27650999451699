import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { Authentication } from './authentication.model';

import { StorageService, StorageResponse } from '../shared/storage.service';

interface AuthenticationResponse
  extends StorageResponse<{ email: string; username: string; role: string }> {}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private tokenTimer: any;

  public user: BehaviorSubject<Authentication> =
    new BehaviorSubject<Authentication>(null);

  constructor(private storage: StorageService, private router: Router) {}

  /**
   * Section I: Password Self Service
   */
  public requestPassword(email: string): Observable<null> {
    return this.storage.POST(
      '/users/reset-password',
      this.storage.createRequest('users', { attributes: { email: email } })
    );
  }

  public resetPassword(token: string, password: string): Observable<null> {
    return this.storage.POST(
      '/users/reset-password/' + token,
      this.storage.createRequest('users', {
        attributes: { password: password },
      })
    );
  }

  public validatePasswordToken(token: string): Observable<null> {
    return this.storage.HEAD('/users/reset-password/' + token);
  }

  /**
   * Section II: Authentication
   */
  public signIn(
    email: string,
    password: string
  ): Observable<AuthenticationResponse> {
    return this.storage
      .POST(
        '/users/sign-in',
        this.storage.createRequest('users', {
          attributes: { email: email, password: password },
        })
      )
      .pipe(
        tap((response) => {
          this.handleAuthentication(response);
          this.router.navigate(['/']);
        })
      );
  }

  public autoSignIn(): void {
    const user: {
      id: string;
      email: string;
      username: string;
      role: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(sessionStorage.getItem('user'));

    if (!user) return;

    const loadedUser = new Authentication(
      user.id,
      user.email,
      user.username,
      user.role,
      user._token,
      new Date(user._tokenExpirationDate)
    );

    if (loadedUser.token) {
      const expiresIn =
        new Date(user._tokenExpirationDate).getTime() - new Date().getTime();

      this.autoSignOut(expiresIn);
      this.user.next(loadedUser);
    } else {
      this.signOut();
    }
  }

  public signOut(): void {
    sessionStorage.removeItem('user');

    this.user.next(null);
    this.router.navigate(['/users', 'sign-in']);

    if (this.tokenTimer) clearTimeout(this.tokenTimer);

    this.tokenTimer = null;
  }

  public autoSignOut(expirationDuration: number): void {
    this.tokenTimer = setTimeout(() => {
      this.signOut();
    }, expirationDuration);
  }

  public handleAuthentication(response: AuthenticationResponse): void {
    const meta = response.meta;
    const data = response.data;
    const attributes = response.data.attributes;

    const expirationDate = new Date(
      new Date().getTime() + meta.expiresIn * 1000
    );
    const user = new Authentication(
      data.id,
      attributes.email,
      attributes.username,
      attributes.role,
      meta.token,
      expirationDate
    );

    sessionStorage.setItem('user', JSON.stringify(user));

    this.user.next(user);

    this.autoSignOut(meta.expiresIn * 1000);
  }
}
