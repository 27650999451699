import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  pure: false,
})
export class SortPipe implements PipeTransform {
  transform(value: any, property: string): any {
    // Prevent issues when using the Angular 'async' pipe, which initially returns 'null'.
    if (!value) {
      return value;
    }

    // Handle the alphabetic sorting operation on the input array 'value'.
    return value.sort((a, b) => {
      if (a[property] === null) return -1;

      if (b[property] === null) return +1;

      if (a[property] > b[property]) return +1;

      if (a[property] < b[property]) return -1;
      else return 0;
    });
  }
}
